export const getCenterCoordinates = () => {
  const center: any = {
    lat: 35.1772203,
    lng: 135.0362909
  }
  return center
}
export const getDefaultZoomLevel = () => {
  const zoom: number = 16
  return zoom
}
